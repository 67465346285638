<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-small uk-table-divider">
        <thead class="thead-table-paranje">
          <tr>
            <th class="uk-width-small">
              <span class="uk-text-bold">Kategori</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Sub-Kategori</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Kegiatan</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Keterangan</span>
            </th>
            <th class="uk-width-small">
              <span class="uk-text-bold">Output</span>
            </th>
          </tr>
        </thead>
        <template v-if="!is_loading">
          <tbody v-if="activityLogDetails.meta.total_count>0">
            <tr
              v-for="(act, i) in activityLogDetails.data"
              :key="i"
            >
              <td>{{ act.activity_category || '-' }}</td>
              <td>{{ act.sub_activity_category || '-' }}</td>
              <td>{{ act.name || '-' }}</td>
              <td>{{ act.description || '-' }}</td>
              <td>{{ formatFloat(act.output) || '' }}</td>
            </tr>
          </tbody>
          <empty-table
            v-else
            :colspan="5"
          />
        </template>
        <template v-else>
          <loading-table :colspan="5" />
        </template>
      </table>
    </div>
    <pagination
      :total-data="activityLogDetails.meta.total_count"
      :change-limit="changeLimit"
      :change-page="changePage"
      :current-page="metaActivityLogDetail.page"
      :current-limit="metaActivityLogDetail.limit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Pagination from '@/components/globals/Pagination'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { STATUS } from '@/utils/constant'
import { dateUtcParanjeString } from '@/utils/formater'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    Pagination
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    dataId: {
      type: String
    }
  },
  data() {
    return {
      is_loading: true,
      status: STATUS,
      images: PREFIX_IMAGE,
      dataDetails: {}
    }
  },
  computed : {
    ...mapGetters({
      activityLogDetails: 'activityLogDetail/activityLogDetails',
      metaActivityLogDetail: 'activityLogDetail/meta'
    })
  },
  watch: {
    async metaActivityLogDetail() {
      this.is_loading = true
      await this.getActivityLogDetail(this.metaActivityLogDetail)
      this.is_loading = false
    },
    async activityLogDetails() {
      this.dataDetails = this.activityLogDetails
      this.is_loading = false
    },
    async dataId() {
      await this.findByActivityId(this.dataId)
      this.is_loading = false
    }
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    ...mapActions({
      getActivityLogDetail: 'activityLogDetail/getActivityLogDetail'
    }),
    ...mapMutations({
      setMetaActivityLogDetail: 'activityLogDetail/SET_META',
      setModalDelete: 'user/SET_MODAL_DELETE'
    }),
    formatFloat(number) {
      return number.replace('.', ',')
    },
    async findByActivityId(id) {
      this.setMetaActivityLogDetail({
        ...this.metaActivityLogDetail,
        log_activity_id: id
      })
    },
    changeLimit(e) {
      this.setMetaActivityLogDetail({
        ...this.metaActivityLogDetail,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.setMetaActivityLogDetail({
        ...this.metaActivityLogDetail,
        page: value
      })
    },
    showDetailUser(id) {
      this.$router.push(`/admin/user/detail/${id}`)
    }
  }
}
</script>
