<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail Kegiatan "{{ activityCageDetail.data.name }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container
      :data-activity="activityCageDetail"
      :contract-price="contractPrice"
    />
    <div id="container-modal-detail-user" />
    <modal-delete-confirm />
    <modal-contract-price />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalDeleteConfirm from './ModalDeleteConfirm'
import ModalContractPrice from './ModalContractPrice'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalDeleteConfirm,
    ModalContractPrice,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/kegiatan-kandang',
          title: 'Kegiatan Kandang'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      activityCageDetail: 'activityCage/activityCageDetail',
      contractPrice: 'contractPrice/priceDetail'
    })
  },
  async mounted() {
    await this.getActivityCageDetail(this.$route.params.id)
    await this.getPriceDetail(this.activityCageDetail.data.contract_price_id)
  },
  methods: {
    ...mapActions({
      getActivityCageDetail: 'activityCage/getActivityCageDetail',
      getPriceDetail: 'contractPrice/getPriceDetail'
    })
  }
}
</script>
