<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-3@m uk-width-1-5@l uk-width-1-5@xl">
      <div id="toggle-animation">
        <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
          <ul
            v-if="isShow"
            id="sidebar"
            class="uk-nav-default uk-nav-parent-icon sidebar-menu"
            uk-nav
          >
            <li
              v-for="(items, i) in filterSidebarActivity"
              :key="items.name"
              :class="[showSidebarClass(items, items.childDisplay), {'active-li': items.name === showAct}, {'list-menu': true}]"
            >
              <a
                :href="items.to"
                class="sidebar-display"
                :class="{'active-child': items.name === showAct}"
                @click="showActionContainer(items, i)"
              >
                <span class="icon-dropdown-pemeliharaan uk-margin-small-right">
                  {{ items.name }}
                </span>
              </a>

              <div
                v-if="items.name === 'PANEN' && screenWidth"
                class="uk-padding-remove"
                uk-dropdown
              >
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav uk-dropdown-nav uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <ul
                  v-if="items.childDisplay && items.name === 'PANEN'"
                  class="uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>                
              </template>

              <div
                v-if="items.name === 'PEMELIHARAAN' && screenWidth"
                class="uk-padding-remove"
                uk-dropdown
              >
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav uk-dropdown-nav uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <ul
                  v-if="items.childDisplay && items.name === 'PEMELIHARAAN'"
                  class="uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child, idx) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), , {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      :to="child.to"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child,idx)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
          
          <ul
            v-else
            class="uk-nav-default uk-nav-parent-icon"
            uk-nav
          >
            <li
              v-for="items in filterSidebarActivity"
              :key="items.name"
              :class="getSidebarClass(items.to)"
            >
              <router-link :to="items.to">
                <span :uk-icon="items.icon" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-2-3@m uk-width-4-5@l uk-width-4-5@xl left-repeat">
      <!-- <div v-bind:key="items.name" v-for="items in filterSidebarMaster"> -->
      <!-- <div id="kandang-card-activity" class="uk-card uk-card-default kandang-card" v-show="showAct === 'PERSIAPAN KANDANG'">
      <span>PERSIAPAN</span>
    </div> -->
      <div class="uk-card uk-card-default kandang-card">
        <!-- <div uk-margin v-for="(row, i) in dataActivityLogs" :key="i"> -->
        <div uk-margin>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tahapan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.name"
                    class="uk-input"
                    type="text"
                    name="name"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Tanggal Pelaksanaan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <div class="uk-form-controls">
                    <input
                      v-model="dataDetailActivity.time_start"
                      class="uk-input"
                      type="text"
                      name="time_start"
                      disabled
                    >
                  </div>
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Total Kegiatan
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.total_activity"
                    class="uk-input"
                    type="text"
                    name="total_activity"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Populasi (ekor)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.population"
                    class="uk-input"
                    type="text"
                    name="population"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Deplesi (ekor)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.dead_chicken"
                    class="uk-input"
                    type="text"
                    name="dead_chicken"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Bobot Sampel (gr)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.weight_sample"
                    class="uk-input"
                    type="text"
                    name="weight_sample"
                    disabled
                  >
                </div>
              </div>
              <div
                class="uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                  Stok Pakan (sak)
                </div>
                <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                  <input
                    v-model="dataDetailActivity.food_stock"
                    class="uk-input"
                    type="text"
                    name="food_stock"
                    disabled
                  >
                </div>
              </div>
            </div>
          </div>
          <div>
            <TableDetailLog
              :data-id="dataDetailActivity.id"
              :data-detail="detailActivities"
            />
          </div>
        </div>
      </div>
    <!-- <div id="kandang-card-activity" class="uk-card uk-card-default kandang-card" v-show="showAct === 'CHICK IN'">
      <span>CHICK IN</span>
    </div>
    <div id="kandang-card-activity" class="uk-card uk-card-default kandang-card" v-show="showAct === 'SANITASI'">
      <span>Sanitasi</span>
    </div>
    <div id="kandang-card-activity" class="uk-card uk-card-default kandang-card" v-show="showContActitvity">
      <div uk-margin v-for="(row, i) in formActivities" :key="i">
        <div class="uk-grid">
          <div class="uk-width-1-2">
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Tahapan</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.sub_category"
                  type="text"
                  name="sub_category"
                  disabled
                >
              </div>
            </div>
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Tanggal Pelaksanaan</div>
              <div class="uk-width-3-5">
                <div class="uk-form-controls">
                  <input
                    class="uk-input"
                    v-model="row.category"
                    type="text"
                    name="category"
                    disabled
                  >
                </div>
              </div>
            </div>
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Total Kegiatan</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.sub_category"
                  type="text"
                  name="sub_category"
                  disabled
                >
              </div>
            </div>
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Populasi (ekor)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.sub_category"
                  type="text"
                  name="sub_category"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-width-1-2">
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Ayam Mati (ekor)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.type_input"
                  type="text"
                  name="type_input"
                  disabled
                >
              </div>
            </div>
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Bobot Sampel (gr)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.processing_time"
                  type="text"
                  name="processing_time"
                  disabled
                >
              </div>
            </div>
            <div
    class="uk-child-width-expand@s uk-grid-small"
                uk-grid
            >
              <div class="uk-width-2-5">Stok Pakan (sak)</div>
              <div class="uk-width-3-5">
                <input
                  class="uk-input"
                  v-model="row.processing_time"
                  type="text"
                  name="processing_time"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
        <br>
        <hr v-show="formActivities.length > 1">
      </div>
      <h2 class="add-activity-line" @click="addActivities">
        <span>
          <img v-lazy="`${images}/icon/plus-activity.svg`" alt="" class="uk-margin-small-left" />
          Tambah Kegiatan
        </span>
      </h2>
    </div> -->
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import TableDetailLog from "./table"

export default {
  components: {
    TableDetailLog
  },
  props: {
    idActivity: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      isShow: true,
      showAct: '',
      images: PREFIX_IMAGE,
      showProduction: process.env.VUE_APP_ENV == 'production',
      metaFilterActivityLog: {
        log_cycle: ''
      },
      metaFilterActivityLogDetail: {
        log_activity_id: ''
      },
      formActivities: [{
        activity_id: null,
        category: '',
        sub_category: '',
        description: '',
        type_input: '',
        processing_time: ''
      }],
      // activities: [{ id: 1, code: 'Cek Suhu'}],
      daysTo: 1,
      showContActitvity: false,
      filterSidebarActivity: [],
      tempActivities : [],
      detailActivityChoose: '',
      dataDetailActivityCategorys: [],
      dataActivityLogs: [],
      dataDetailActivity: {},
      showContainerLog: 0,
      idDataDetailActivity : '',
      detailActivities: [],
      screenWidth: false
    }
  },

  computed: {
    ...mapGetters({
      activities: 'activity/activities',
      detailActivityCategorys: 'detailActivityCategory/detailActivityCategorys',
      activityLogs: 'activityLog/activityLogs',
      activityLogDetails: 'activityLogDetail/activityLogDetails',
      activityLogWithDetails: 'activityLog/activityLogWithDetails',
      
      metaActivityLog: 'activityLog/meta',
      metaActivityLogDetail: 'activityLogDetail/meta',
      metaDetailActivity: 'detailActivityCategory/meta',
      metaActivity: 'activity/meta'
    }),
    filterSidebarMaster: function(){
      const sider = this.sidebarMaster.filter(item => {
        if(item.role.includes('super_admin')){
          if(this.showProduction){
            if(item.showProduction){
              return item
            }
          }else{
            return item
          }
        }
      })
      return sider
    }
  },
  watch: {
    async metaActivity() {
      this.is_loading = true
      await this.getActivity(this.metaActivity)
      this.is_loading = false
    },
    async idActivity() {
      this.is_loading = true
      this.screenWidth = screen.width <= 768
      // await this.getActivityLog(this.idActivity)
      await this.getActivityLogWithDetail(this.idActivity)
      this.is_loading = false
      this.dataActivityLogs = this.activityLogWithDetails ? this.activityLogWithDetails : []

      await this.maintenanceDays()
      const getItemsFirst = this.activityLogWithDetails ? this.activityLogWithDetails[0] : []

      this.idDataDetailActivity = getItemsFirst.id
      const assignOnLoad = {
        log_detail_activity: getItemsFirst.log_detail_activity,
        created_at: getItemsFirst.created_at,
        dead_chicken: getItemsFirst.dead_chicken,
        expire: getItemsFirst.expire,
        food_stock: getItemsFirst.food_stock,
        id: getItemsFirst.id,
        log_cycle_id: getItemsFirst.log_cycle_id,
        name: getItemsFirst.name,
        note: getItemsFirst.note,
        population: getItemsFirst.population,
        sequence: getItemsFirst.sequence,
        updated_at: getItemsFirst.updated_at,
        updated_by: getItemsFirst.updated_by,
        weight_sample: getItemsFirst.weight_sample,
        showProduction: true,
        to: '#',
        icon: 'icon: triangle-right',
        role: ['super_admin'],
        displayMaintenance: true,
        childDisplay: false,
        children    : []
      }
      await this.showActionContainer(assignOnLoad, 0)
    }
    // async metaActivityLogDetail() {
    //   this.detailActivities = this.activityLogDetails
    // }
  },
  async mounted() {
    await this.changeLimitActivityLog()
  },
  methods: {
    ...mapActions({
      getActivity: 'activity/getActivity',
      getDetailActivityCategory: 'detailActivityCategory/getDetailActivityCategory',
      getActivityLog: 'activityLog/getActivityLog',
      activityLogDetail: 'activityLogDetail/activityLogDetail',
      getActivityLogWithDetail: 'activityLog/getActivityLogWithDetail'
    }),
    ...mapMutations({
      setMetaActivity: 'activity/SET_META',
      setMetaDetailActivity: 'detailActivityCategory/SET_META',
      setMetaActivityLog: 'activityLog/SET_META',
      setMetaActivityLogDetail: 'activityLogDetail/SET_META'
    }),
    async changeLimitActivity() {
      this.setMetaActivity({
        ...this.metaActivity,
        limit: 100
      })
    },
    async changeLimitActivityLog() {
      this.setMetaActivityLog({
        ...this.metaActivityLog,
        limit: 100
      })
    },
    async maintenanceDays() {
      const dataActivity = this.dataActivityLogs ? this.dataActivityLogs : []
      this.filterSidebarActivity = []
      const menuChilds = []
      const menuPanenChilds = []
      let child = {}
      let panenChild = {}
      let menu = {}
      dataActivity.forEach(el => {
        if (el.name.search('HARI KE') == 0) {
          child = {
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            menu_include: 'Pemeliharaan',
            children    : []
          }
          menuChilds.push(child)
        } else if (el.name.search('PANEN HARI KE') == 0) {
          panenChild = {
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            menu_include: 'Panen',
            children    : []
          }
          menuPanenChilds.push(panenChild)
        } else {
          menu = {
            log_detail_activity: el.log_detail_activity,
            created_at: el.created_at,
            dead_chicken: el.dead_chicken,
            expire: el.expire,
            food_stock: el.food_stock,
            id: el.id,
            log_cycle_id: el.log_cycle_id,
            name: el.name,
            note: el.note,
            population: el.population,
            sequence: el.sequence,
            updated_at: el.updated_at,
            updated_by: el.updated_by,
            weight_sample: el.weight_sample,
            showProduction: true,
            to: '#',
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: false,
            childDisplay: false,
            children    : []
          }
          this.filterSidebarActivity.push(menu)
        }
      })
      // ASSIGN DAYS PEMELIHARAAN
      let maintenanceMenu = {}
      let harvestMenu = {}
      if (dataActivity.length > 2) {
        maintenanceMenu = {
          name: 'PEMELIHARAAN',
          showProduction: true,
          to: '#',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : menuChilds
        }
        this.filterSidebarActivity.splice(2, 0, maintenanceMenu)
      }
      if (dataActivity.length > 0) {
        harvestMenu = {
          name: 'PANEN',
          showProduction: true,
          to: '#',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : menuPanenChilds
        }
        this.filterSidebarActivity.splice(3, 0, harvestMenu)
      }
      dataActivity.forEach(el => {
        const log = {
          name: el.name,
          sequence: el.sequence,
          note: el.note,
          log_detail_activity: []
        }
        this.tempActivities.push(log)
      })
    },
    async showDetailActivity(id) {
      if(id){
        this.metaFilterActivityLogDetail.log_activity_id = id
        this.setMetaActivityLogDetail({
          ...this.setMetaActivityLogDetail,
          ...this.metaFilterActivityLogDetail
        })
      }
    },
    async showActionContainer(items, i) {
      this.idDataDetailActivity = items.id
      await this.showDetailActivity(this.idDataDetailActivity)
      this.daysTo = null
      if (!items.childDisplay) {
        this.showAct = items.name
        this.showContActitvity = false
        this.showContainerLog = i
      } else {
        this.showAct = ''
      }

      if (items.name !== 'PEMELIHARAAN') {
        this.dataDetailActivity = {
          created_at: items.created_at,
          dead_chicken: items.dead_chicken,
          expire: items.expire,
          food_stock: items.food_stock,
          id: items.id,
          log_cycle_id: items.log_cycle_id,
          name: items.name,
          note: items.note,
          population: items.population,
          sequence: items.sequence,
          updated_at: items.updated_at,
          updated_by: items.updated_by,
          weight_sample: items.weight_sample,
          time_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
          total_activity: items.log_detail_activity.length
        }
      }
    },
    findActivityLogByID(id) {
      this.dataActivityLogs = []
      // FILTER FIND
      this.metaFilterActivityLog.log_cycle = id
      this.setMetaActivityLog({
        ...this.metaActivityLog,
        ...this.metaFilterActivityLog
      })
    },
    findActivityLogChangeLimit() {
      // FILTER FIND
      this.metaFilterActivityLog.limit = this.dataActivityLogs.meta.total_count
      this.setMetaActivityLog({
        ...this.metaActivityLog,
        ...this.metaFilterActivityLog
      })
    },
    showContainerActivity(items,index) {
      if (items.menu_include === 'Panen') {
        this.daysTo = index + 1
        this.showAct = `PANEN HARI KE ${this.daysTo}`
        this.dataDetailActivity = {
          created_at: items.created_at,
          dead_chicken: items.dead_chicken,
          expire: items.expire,
          food_stock: items.food_stock,
          id: items.id,
          log_cycle_id: items.log_cycle_id,
          name: items.name,
          note: items.note,
          population: items.population,
          sequence: items.sequence,
          updated_at: items.updated_at,
          updated_by: items.updated_by,
          weight_sample: items.weight_sample,
          time_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
          total_activity: items.log_detail_activity.length
        }
      } else {
        this.daysTo = index + 1
        this.showAct = `HARI KE ${this.daysTo}`
        this.dataDetailActivity = {
          created_at: items.created_at,
          dead_chicken: items.dead_chicken,
          expire: items.expire,
          food_stock: items.food_stock,
          id: items.id,
          log_cycle_id: items.log_cycle_id,
          name: items.name,
          note: items.note,
          population: items.population,
          sequence: items.sequence,
          updated_at: items.updated_at,
          updated_by: items.updated_by,
          weight_sample: items.weight_sample,
          time_start: items.log_detail_activity[0] ? items.log_detail_activity[0].time_start.split(" ")[0] || "-" : "-",
          total_activity: items.log_detail_activity.length
        }
      }
      // this.showContActitvity = true
      this.findActivityLogByID(items.id)
    },
    showSidebarActiveChildClass() {
      return 'active-child'
    },
    showDetailSidebar() {
      this.isShow = !this.isShow
    },
    getSidebarClass(path){
      return{
        'active': this.$route.fullPath === this.$route.fullPath+path,
        'inactive': this.$route.fullPath !== this.$route.fullPath+path
      }
    },
    showChildSidebar(path, child) {
      const classChild = path.name === 'PEMELIHARAAN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showHarvestChildSidebar(path, child) {
      const classChild = path.name === 'PANEN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showSidebarClass (path, child) {
      const active = this.getSidebarClass(path)
      const childs = this.showChildSidebar(path, child)
      const harvestChilds = this.showHarvestChildSidebar(path, child)
      const classes = `${active} ${childs} ${harvestChilds}`
      return classes
    }
  }
}
</script>

<style scoped>
.width-large {
  width: 20.5%;
}
.width-small {
  width: 7%;
}
#toggle-animation {
  transition: width 500ms;
}
.card-sidebar {
  height: calc(100vh - 50px);
}
.detail-sidebar {
  padding-right: 10%;
  background-color: #E0EBE6;
  height: 50px;
  line-height: 50px;
}
.detail-sidebar a {
  color: #ffffff;
}
.sidebar {
  background-color: #E0EBE6;
  border-radius: 16px 0px 0px 16px;
  height: auto;
}
li {
  padding: 8px 0;
}
.uk-active {
  background-color: #004428;
  border-left: 5px solid #004428;
}

.uk-active .uk-icon {
  background-color: #f1f2f3 ;
  color: #f1f2f3;
}
li.uk-active a {
  color: #cacccb;
}
.uk-nav-header {
  color: #fff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active{
  background-color: #004428;
  color: #004428;
}
.active > a{
  color: #fff !important;
}
.uk-nav-default .uk-nav-sub a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
}
.uk-card-body .uk-nav-default > li > a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
}
@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }
}
.uk-nav-default .uk-nav-sub a {
    margin-left: 30px;
}
.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 0px 0px 16px;
}
.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus, .uk-nav-default .uk-nav-sub a:hover, .uk-parent a:focus {
  color: #84deb9;
}
.left-repeat {
  padding-left: 0px;
}
#kandang-card-activity {
  height: 522px;
}
.uk-nav-sub > li > a:focus, .uk-nav-sub > li > a:hover {
  color: #84deb9;
}

.uk-card-body .uk-nav-default .uk-nav-sub {
    padding-left: 0px;
}
ul.uk-nav-sub {
    padding: 0px;
    height: auto;
    max-height: 360px;
    overflow: auto;
}
.active-child {
  color:#025231 !important;
}
.active-li {
  background-color: #FFFF;
  border-radius: 16px 0px 0px 16px;
}
.icon-dropdown-pemeliharaan {
  z-index: 100; 
  margin-right:0px !important;
}
.sidebar-display {
  display:flex; 
  flex-direction: row; 
  justify-content: space-between; 
  padding-right: 0px !important;
}

.sidebar-menu {
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  padding: 0 15px;
}

.list-menu {
  min-width: 70%;
}

.active-li {
  background-color: #FFFF;
  border-radius: 16px 16px 16px 16px;
}

.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 16px 16px 16px;
}

.sub-sidebar-menu {
  position: absolute;
  left: 0;
  background-color: #f1f3f2;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (min-width: 768px) {
  .list-menu {
    min-width: 45%;
  }
}

@media (min-width: 1024px) {
  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}

@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }

  .uk-card-body .uk-nav-default .uk-nav-sub {
      padding-left: 0px;
  }

  .left-repeat {
    padding-left: 0px;
  }

  .active-li {
    border-radius: 16px 0px 0px 16px;
  }

  .uk-maintenance {
    border-radius: 16px 0px 0px 16px;
  }

  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    border-radius: 0 0 10px 10px;
  }

}
</style>
